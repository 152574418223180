<template>
  <div class="flex gap-4 justify-center">
    <div
      class="rounded-lg px-2 w-32 py-1 relative z-10 border border-nColorBlue"
    >
      <h2
        ref="counterText"
        class="font-InterExtraBold text-xl text-center text-nColorYellow"
      >
        Score: {{ score }}
      </h2>
    </div>
    <div
      class="rounded-lg w-40 px-4 py-1 relative z-10 border border-nColorBlue"
    >
      <h2
        id="counterText"
        ref="counterText"
        class="font-InterExtraBold text-xl flex justify-center text-nColorYellow"
      >
        <div class="w-10 text-center">
          {{ formatTimer(displayTime, displayTime.minute) }}
        </div>
        :
        <div class="w-10 text-center">
          {{ formatTimer(displayTime, displayTime.sec) }}
        </div>
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "Counter",
  props: {
    score: {
      required: true,
    },
    displayTime: {
      required: true,
    },
    background: {
      required: false,
      default: () => "orange",
    },
    textcolor: {
      required: false,
      default: () => "black",
    },
    showIcons: {
      required: false,
      default: () => true,
    },
  },
  methods: {
    formatTimer(displayTime, time) {
      if (displayTime.minute == 0 && displayTime.sec <= 10) {
        document
          .getElementById("counterText")
          .classList.remove("text-nColorYellow");
        document
          .getElementById("counterText")
          .classList.add("text-nColorDarkRed");
      }
      return time > 9 ? "" + time : "0" + time;
    },
  },
};
</script>

<style scoped></style>
