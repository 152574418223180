export const config = {
  url: process.env.VUE_APP_API_URL,
  env: process.env.NODE_ENV,
};

export const apiResource = {
  userLogin: "/bCl-Catvlq7vZTvsVjGM4AbKGWs2PyJOTeRwIicwDpw=",
  validateUserIdentifier: "/bQuP7sac8GDBnoWAbieb-_AOAnyWOGTGU4CTGjRRxeQ=",
  getUserInformationFromIdentifier:
    "/g6zJKyKLgePQQEhXEOGx93ePLyrLl3vpdv4W4kDRCB8=",
  validateCustomer: "/7BUXsewaL2PSnh2jeWQ4CgccY0_F-_AbvafW3TLcf_Q=",
  validateOTP: "/6DQ2cycjBTd2T6LiCOI4DiTiq7v6XBi5peGW81XG998=",
  resendOTP: "/02QipOr9fLXgjyjd7xqL54gtKGEERggJtZf-d-I4Wf4=",
  loginValidateEmail: "xfky9cjKd43BaDjJkrNu-xWpwcouYc_FPzW2NKe5OFw=",
  loginValidateOTP: "Fdhyf97OR6gd_r5mOmVYEy8CSNmpSFpGW9GXYLvB9eY=",
  loginResendOTP: "0aN2p5ZbkWpLiNC88POwMrsBpgHk6PF3Ip7McwO8qKs=",
  signupUsingGoogle: "/-pnaT5_chxIz7scfs68Idhb8mQPIb4bSxUy_dBLUFRc=",
  loginWithGoogle: "/NxUwlemJ5FQo-vf6EqX2QCVwNPYHQdTulli8O3k-RWo=",
  updateInterests: "/3gPyBTTnIPilT16vmEML4wVOnbtxkW3z5XpeVx1yQEU=",
  profile: "/l9uQCVnZVEF4iWWM73ZoGaMomq6E6rWQN4GOt4FNzs4=",
  getCurrentTime: "/v1/get-current-time",
  getLobbyDetails: "/v1/get-lobby-details",
  getCatalogDetails: "/v1/get-catalog-details",
  getGameDetails: "/v1/get-game-details",
  updatePlayGameStatus: "/AYCTMaGLQbwgRvDak1PsEEL7Ird863CTF4DVuRIaNAU=",
  saveFindMeScore: "/4FgKpcKk2m3m2zbqeZ9M-VV5uZJEFRNBEXjOe5VfepA=",
  saveWhackoScore: "/nSl1PInBA7l-tsmqYfWjVmtJJXlx1C3SqknyELZ3mwA=",
  saveHexaDropScore: "/Cj-yz5L7cANpJDeVl731P20CZoUJ81jiDfEkNQ977tk=",
  saveTrueFalseScore: "/ll5tSHwxYAnfGDJlHxFjz5o3Gc5avhNZjAtsovNaIZQ=",
  saveGTMScore: "/kWgRbJcwxNw81Fbsp7kp0_smTP9JK4yZL78n46LTD5k=",
  saveWhatCameFirstScore: "/qktCZmK8BP6G88l4dkJlF6M3hGgI2GXXPPjnJ9odq94=",
  saveMergeFacesScore: "/hF6z-khSocwZcV7B-7VSokcKTjoe4EnpSTzBiqFOQvY=",
  saveMemorizeScore: "/EsoGDqQoiSSBhBnnzaQjYyrZKNIY-PrR9hZkeTh8fl0=",
  saveMakeWordsScore: "/z3EgqHWgMvt46PIy-gHCYFgVdU4gQUSPiIEN9jE3pfM=",
  savePourDaScore: "/clo7XAvZbo-FEJVLI_AtpQ7wEENSxrN_leC7REdMFsQ=",
  saveDoodleMatchScore: "/ZDHQeOXoVxLjwatbaZVKu1AUd0ofOhJj8pDIVrbyC0s=",
  saveFieldMasterScore: "/EA1Yju5NBV0Fi3uiA9O12AOe3jhiWpQI0M__70TeTbI=",
  saveBatMasterScore: "/XujHDlowex0A0Lw93upDphgMy2xIuO_sOZzeP8MMjaI=",
  saveLyricLingoScore: "/n1g-N7gaPv6eDEsIaK1D9jJ0HS16s_SnX4SqCk7CTis=",
  saveQuizzicalScore: "WznYose7OX_IWyNpXA1Ac2vWk_ABu6F2-zvk4JuRHvw=",
  saveSlayNayScore: "/Ctedx6ksz1A_gxcooGEkXePvSXLzZaBAwcXGVGRCm3A=",
  saveSlanguageScore: "2go8vILQ4aC8Hm5-CA1Tps7LpeeVlkVgSqteDSc_D4I=",
  getUserFeedbackForSchedule: "/2sdVa8m1dM3RWPnLGp19ElG03iKrPwCUtHVuBTuO94c=",
  saveGameFeedback: "/dQNuWyalyzOQ5yuhixOqwlSp5YbZZv4k6W4Imat0vj0=",
  getLeaderboard: "/Hd7Adigp4UQCkiDEPUV5UdzRzWyACYfnfj9seA5vBnI=",
  rewards: {
    getDetails: "/yjfA00nDv3bNna2teFO5rvV0Rf4qowXuDzK8ZeQPtis=",
    sendVoucher: "/Zvjn4sBfgF04UcX-88-MF0EKBq_8icRnfpNB_vYntxM=",
  },
};
